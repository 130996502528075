import "./App.css";
import Header from "./components/Header";
import Section from "./components/Section";
import { useState } from "react";
import AWS from "aws-sdk";
import { toast, Toaster } from "react-hot-toast"; // Import toast from react-hot-toast
import AudioPlayer from "./components/AudioPlayer";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";

import Pricing from "./pages/Price";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermandConditions from "./pages/TermConditions";
import Home from "./pages/home";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/termsandconditions" element={<TermandConditions />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
