import React, { useState, useEffect, useRef } from "react";
import logo from "../assets/resume/logo.png";
import { NavLink } from "react-router-dom";
import "./Styles.css";
import { IoSearchSharp } from "react-icons/io5";
// import Input from "./input";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "../FirebaseConfig";
import Goo from "../assets/icons/Picture1.png";
import { useSelector, useDispatch } from "react-redux";
import { loginUser, logoutUser, language } from "../Redux/authAction";
import Avatar from "../components/avatar";
import axios from "axios";
import { useTranslation } from "react-i18next";
import login from "../assets/home/gmail.jpg";
import "./styless.css"



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "clamp(750px,100%,300px)",
  height: "600px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

const CategoryList = ({ categories, onCategoryClick }) => {
  const mainCategories = categories.slice(0, 3);
  const dropdownCategories = categories.slice(3);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleDropdownClick = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  const handleMenuItemClick = (category) => {
    onCategoryClick(category);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className="flex gap-1 items-center cursor-pointer hover:no-underline">
     


      {dropdownCategories.length > 0 && (
        <div className="relative inline-block" ref={dropdownRef}>
          <button
            className="text-[#2e2d2d] font-[500] text-[14px] pl-3 cursor-pointer focus:outline-none"
            onClick={handleDropdownClick}
          >
            Shop Now
          </button>
          {isDropdownOpen && (
            <div className="absolute mt-2 space-y-2 bg-white border rounded-md shadow-lg w-[200px]">
              {dropdownCategories.map((category, index) => (
                <NavLink
                  to={`/category/${category}`}
                  className="hover:no-underline"
                >
                  <p
                    key={index}
                    onClick={() => handleMenuItemClick(category)}
                    className="block px-4 py-2 text-[#2e2d2d] font-[500] text-[14px] cursor-pointer"
                  >
                    {category}
                  </p>
                </NavLink>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const Header = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logEmail, setLogEmail] = useState("");
  const [logPassword, setLogPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [google, setGoogle] = useState(null);
  const [categories, setCategories] = useState([]);

  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  const isLang = useSelector((state) => state.authReducer.lang);
  console.log("isAuthenticated", isAuthenticated);
  const userName = useSelector((state) => state.authReducer.name);
  const emails = useSelector((state) => state.authReducer.email);

  const handleOpen = () => {
    setOpen(true);
  };
  const handelOpenModel = () => {
    setOpenModel(true);
  };
  const handleClose = () => setOpen(false);
  const handleCloseModel = () => setOpenModel(false);
  // Custom
  const handleSignup = async () => {
    try {
      if (name && email && password) {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        updateProfile(userCredential.user, {
          displayName: name,
        });
        handleCloseModel();
      } else {
        console.error(
          "Please enter valid name, email, and password.",
          email,
          password
        );
      }
    } catch (error) {
      console.error("Error during signup:", error);
      if (error.code === "auth/operation-not-allowed") {
        console.error(
          "Firebase authentication operation not allowed. Check Firebase Console settings."
        );
      } else {
        console.error("Unexpected error:", error.message);
      }
    }
  };
  useEffect(() => {
    auth.onAuthStateChanged((user) => {});
  }, []);

  const handleLogin = async () => {
    try {
      if (logEmail && logPassword) {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          logEmail,
          logPassword
        );
      } else {
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.maker4u.com/getcategories.php"
        );
        setCategories(response.data.map((items) => items?.title));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  console.log("categories", categories);

  const handleGoogleLogin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        setGoogle(user);
        const { displayName, email } = user;
        dispatch(loginUser({ displayName, email }));
      })
      .catch((error) => {
        alert("error");
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
  };
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (result) => {
      if (result) {
        const { displayName, email } = result;
      } else {
      }
    });
    return () => unsubscribe();
  }, []);
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("User signed out successfully");
    } catch (error) {
      console.error("Error during sign-out:", error);
    }
  };
  const handlesignout = () => {
    dispatch(logoutUser());
  };

  const handleCategoryClick = (category) => {
    console.log(`Clicked on category: ${category}`);
  };

  return (
    <>
      {isLang === "eng" ? (
        <div className=" flex items-center px-10 shadow-lg h-20 z-50  p-1   w-full bg-white">
          <div className="flex gap-6">
            <img
              src={`${logo}`}
              className="cursor-pointer w-[200px] h-[60px]"
            />

            <a
              src="/"
              className="flex gap-1 items-center cursor-pointer -ml-3 focus:outline-none  hover:no-underline"
            >
              <p className="text-[#2e2d2d]  font-[500] text-[14px] btns"> Home</p>
            </a>


            <a
              src="/"
              className="flex gap-1 items-center cursor-pointer -ml-3 focus:outline-none  hover:no-underline"
            >
              <p className="text-[#2e2d2d]  font-[500] text-[14px] btns"> Text to Speech</p>
            </a>


            <a
              src="/"
              className="flex gap-1 items-center cursor-pointer -ml-3 focus:outline-none  hover:no-underline"
            >
              <p className="text-[#2e2d2d]  font-[500] text-[14px] btns"> Voice to Video</p>
            </a>

            <a
              src="/"
              className="flex gap-1 items-center cursor-pointer -ml-3 focus:outline-none  hover:no-underline"
            >
              <p className="text-[#2e2d2d]  font-[500] text-[14px] btns"> Video to Cartoon</p>
            </a>

            <a
              src="../pages/price"
              className="flex gap-1 items-center cursor-pointer -ml-3 focus:outline-none  hover:no-underline"
            >
              <p className="text-[#2e2d2d]  font-[500] text-[14px] btns"> Pricing</p>
            </a>


            <a
              src="/category"
              className={"flex gap-1 items-center cursor-pointer hover:no-underline  focus:outline-none"}
            >
              <p className="text-[#2e2d2d]  font-[500] text-[14px] text-nowrap ">
               About Us
              </p>
            </a>

            <a
              src ="/ResumeMaker/all"
              className="flex gap-1 items-center cursor-pointer hover:no-underline focus:outline-none"
            >
              <p className="text-[#2e2d2d]  font-[500] text-[14px] text-nowrap">
                Contact Us
              </p>
            </a>



           
          </div>

          <div style={{ flexGrow: 1 }}></div>
          <div className="flex items-center gap-4 text-black ">
           
            {isAuthenticated ? (
              <button
                onClick={handlesignout}
                className="text-[#2e2d2d]  font-[500] text-[14px] text-nowrap hover:underline"
              >
                Sign Out
              </button>
            ) : (
              <button
                onClick={handleOpen}
                className="text-[#2e2d2d] focus:outline-none  font-[500] text-[14px] text-nowrap hover:underline"
              >
                Sign In
              </button>
            )}
            {isAuthenticated ? null : (
              <button
                onClick={handelOpenModel}
                className="text-[#2e2d2d] focus:outline-none font-[500] text-[14px] text-nowrap hover:underline"
              >
                Sign Up
              </button>
            )}

            {isAuthenticated ? <Avatar /> : null}
          </div>
        </div>
      ) : (
        <div
          className=" flex items-center px-10 shadow-lg h-20 z-50  p-1"
          style={{ direction: "rtl" }}
        >
          <div className="flex gap-6">
            <img
              src={`${logo}`}
              className="cursor-pointer w-[100px] h-[60px]"
            />

            <a
              src="/"
              className="flex gap-1 items-center cursor-pointer hover:no-underline focus:outline-none"
            >
              <p className="text-[#2e2d2d]  font-[500] text-[20px]">بيت </p>
            </a>

            <a
              src="/category"
              className={
                "flex gap-1 items-center cursor-pointer hover:no-underline focus:outline-none"
              }
            >
              <p className="text-[#2e2d2d]  font-[500] text-[20px] text-nowrap ">
                صانع الترويج
              </p>
            </a>

            <a
              src="/ResumeMaker/all"
              className="flex gap-1 items-center cursor-pointer hover:no-underline focus:outline-none"
            >
              <p className="text-[#2e2d2d]  font-[500] text-[20px] text-nowrap">
                صانع السيرة الذاتية
              </p>
            </a>

            <div className="flex gap-1 items-center cursor-pointer">
              <p className="text-[#2e2d2d]  font-[500] text-[20px] text-nowrap">
                رسالة الغلاف وغيرها
              </p>
            </div>
            <div className="flex gap-1 items-center cursor-pointer">
              <p className="text-[#2e2d2d]  font-[500] text-[20px] text-nowrap">
                عن
              </p>
            </div>
            <a
              src="/price"
              className="flex gap-1 items-center cursor-pointer hover:no-underline focus:outline-none"
            >
              <p className="text-[#2e2d2d]  font-[500] text-[20px] text-nowrap">
                التسعير
              </p>
            </a>
          </div>
          <div style={{ flexGrow: 1 }}></div>
          <div className="flex items-center gap-4 text-black ">
            <div
              onClick={() => dispatch(language("eng"))}
              className={`cursor-pointer ${
                isLang !== "eng" ? "text-[16px] font-[]" : ""
              } `}
            >
              <p>{isLang === "eng" ? "English" : "إنجليزي"}</p>
            </div>

            <div
              onClick={() => dispatch(language("arabic"))}
              className="cursor-pointer"
            >
              <p className="mr-6 font-[600] text-[16px] ">عربي</p>
            </div>

            {isAuthenticated ? (
              <button
                onClick={handlesignout}
                className="text-[#2e2d2d]  font-[500] text-[14px] text-nowrap hover:underline"
              >
                Sign Out
              </button>
            ) : (
              <button
                onClick={handleOpen}
                className="text-[#2e2d2d]  font-[500] text-[14px] text-nowrap hover:underline"
              >
                {isLang === "eng" ? "Sign In" : "تسجيل الدخول"}
              </button>
            )}
            {isAuthenticated ? null : (
              <button
                onClick={handelOpenModel}
                className="text-[#2e2d2d]  font-[500] text-[14px] text-nowrap hover:underline"
              >
                {isLang === "eng" ? "Sign Up" : "اشتراك"}
              </button>
            )}

            <Avatar />
          </div>
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="rounded-lg text-[#262b33]">
          <div className="flex ">
            <div className="w-[50%] h-[70vh] flex flex-col  gap-4 mr-7 ml-2">
              <p className="mb-[30px] mt-[20px] text-[28px] font-[700] relative -left-3">
                Welcome back!
              </p>
              <div>
                <p>Email:</p>
                <input
                  type="text"
                  placeholder="Email"
                  value={logEmail}
                  onChange={(e) => setLogEmail(e.target.value)}
                  className="p-2 border-2 rounded focus:outline-blue-500 w-full bg-slate-50"
                />
              </div>
              <div className="">
                <p>Password</p>
                <input
                  type="password"
                  placeholder="Password"
                  value={logPassword}
                  onChange={(e) => setLogPassword(e.target.value)}
                  className="p-2 border-2 rounded focus:outline-blue-500 w-full bg-slate-50"
                />
              </div>
              <button
                onClick={handleLogin}
                className="bg-gradient-to-r from-[#4c76cf] to-[#552ab8] px-7 py-3 rounded-md text-white text-[16px] font-[600] cursor-pointer "
              >
                Login
              </button>
              <button
                onClick={handleGoogleLogin}
                className="flex items-center bg-white dark:bg-gray-900 border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 dark:text-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                <img src={`${Goo}`} className="h-8" />
                <span>Continue with Google</span>
              </button>
            </div>

            <div className="w-[50%] bg-slate-600">
              <img src={`${login}`} className="h-full" alt="" />
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openModel}
        onClose={handleCloseModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="rounded-lg text-black">
          <div className="flex ">
            <div className="w-[50%] h-[70vh] flex flex-col  gap-4 mr-7 ml-2">
              <p className="mb-[30px] mt-[20px] text-[28px] font-[700] relative -left-3">
                Welcome back!
              </p>
              <div>
                <label>Name:</label>
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full h-9 bg-white    focus:outline-none pl-2"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    borderRadius: "5px",
                    border: "1px solid #63626240",
                  }}
                />
              </div>
              <div>
                <label>Email:</label>
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full h-9 bg-white    focus:outline-none pl-2"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    borderRadius: "5px",
                    border: "1px solid #63626240",
                  }}
                />
              </div>
              <div className="">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full h-9 bg-white    focus:outline-none pl-2"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    borderRadius: "5px",
                    border: "1px solid #63626240",
                  }}
                />
              </div>
              <button
                onClick={handleSignup}
                className="bg-gradient-to-r from-[#4c76cf] to-[#552ab8]  py-2 rounded-md text-white text-[16px] font-[600] cursor-pointer "
              >
                Signup
              </button>
              <button
                onClick={handleGoogleLogin}
                className="flex items-center bg-white dark:bg-gray-900 border border-gray-300 rounded-lg shadow-md px-4 py-[6px] text-sm font-medium text-gray-800 dark:text-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                <img src={`${Goo}`} className="h-8" />
                <span>Continue with Google</span>
              </button>
            </div>

            <div className="w-[50%] bg-slate-600">2</div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Header;
