import "../App.css";
import Section from "../components/Section";
import { useState } from "react";
import AWS from "aws-sdk";
import { toast, Toaster } from "react-hot-toast"; // Import toast from react-hot-toast
import AudioPlayer from "../components/AudioPlayer";
import Header from "../components/Header";
import Feature from "../components/feature";
import FeatureB from "../components/FeatureTwo";
import Footer from "../components/Footer";
import FAQ from "../components/Faq";
import card1 from "../assets/home/img1.jpg";
import card2 from "../assets/home/img5.jpg";
import markersection from "../assets/home/img2.jpg";

import img6 from "../assets/home/img6.PNG";
import img7 from "../assets/home/img7.PNG";
import img8 from "../assets/home/img8.PNG";

import PricingCard from "../components/PricingCard";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { useMediaQuery } from "react-responsive";

const Home = () => {
  const categories = [
    {
      img: img6,
      title: "Text to Speech",
      des: "Text to Speech",
    },

    {
      img: img7,
      title: "Text to Video",
      des: "Text to Video",
    },

    {
      img: img8,
      title: "Video to Cartooni",
      des: "Video to Cartooni",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1100 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1100, min: 830 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 830, min: 650 },
      items: 3,
    },
    mdtablet: {
      breakpoint: { max: 650, min: 400 },
      items: 2,
    },

    mobile: {
      breakpoint: { max: 400, min: 0 },
      items: 1,
    },
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1220px)",
  });

  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width: 900px)",
  });
  const isMobile = useMediaQuery({
    query: "(min-width: 600px)",
  });

  const isCarsial = useMediaQuery({
    query: "(max-width: 400px)",
  });
  const isLang = useSelector((state) => state.authReducer.lang);
  const [pack, setPack] = useState(false);
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_CLIENTID,
    secretAccessKey: process.env.REACT_APP_SECRETKEY,
    region: process.env.REACT_APP_REGION,
  });

  const polly = new AWS.Polly();

  const [text, setText] = useState("");
  const [audioFile, setAudioFile] = useState();

  const convertTextToSpeech = () => {
    polly.synthesizeSpeech(
      {
        Text: text,
        OutputFormat: "mp3",
        VoiceId: "Salli",
      },
      (error, data) => {
        if (error) {
          //console.log(error);
          toast.error("Something went wrong!");
        } else {
          //console.log(data);
          setAudioFile(data);
          // Show toast notification on successful conversion
          toast.success("Text converted to speech successfully!");
        }
      }
    );
  };

  return (
    <>
      <Header />
      <div className="section-container grid grid-cols-12 xl:grid xl:grid-col-12 pt-30 mx-2 xl:mx-10">
        <div className="xl:col-start-1 xl:col-span-5  col-span-12 :col-start-1">
          <h1 className=" text-[65px] font-bold">
            <span
              className="text-[56px] uppercase sm:text-[42px] xl:text-[64px] font-[700] leading-[1.8] "
              style={{
                background:
                  "linear-gradient(to right, #714e91 0%, #5f619f  51%, #433761  100%)",
                backgroundClip: "text",
                color: "transparent",

                lineHeight: "2px",
              }}
            >
              ADVANCE AI 
              TOOLS
            </span>{" "}
          </h1>
          <p className=" text-center xl:text-left ">
            Discover the power of voice with our Text to Speech technology. Our
            platform transforms written content into natural-sounding speech,
            making information more accessible and engaging for everyone.
            Whether you're looking to bring your eBooks to life or make your
            articles more accessible, our solution is here to help.
            <br/><br/> We pride
            ourselves on delivering high-quality audio output that captures the
            nuances of human speech. Our advanced algorithms ensure that the
            voice is not just clear, but also carries the right emotion and
            intonation. This makes our Text to Speech service perfect for
            creators, educators, and businesses aiming to enhance their audio
            content.
          </p>
        </div>

        <div className="xl:col-start-6 xl:col-span-7 col-span-12 col-start-1">
          <div className="container">
            <Section
              text={text}
              setText={setText}
              convertTextToSpeech={convertTextToSpeech}
            />
            <Toaster position="top-right" reverseOrder={false} />
            <AudioPlayer audioFile={audioFile} />
          </div>
        </div>
      </div>

      <section style={{ maxWidth: "1400px", margin: "auto" }}>
        <h1 className="text-center text-3xl text-black m-5 font-bold">OUR SERVICES</h1>
        <div className={`flex bg-slate-50  justify-center   `}>
          <div
            className={`${isCarsial ? "w-[300px] m-auto" : "w-[300px]"} ${
              isLang !== "eng" ? "text-right text-[22px]" : ""
            } h-full block rounded-[22px] p-3 text-[#282828] border`}
          >
            {/* Use the fetched image directly in the src attribute */}
            <img
              className="w-full  rounded-t-[6px] object-cover"
              src={img6}
              alt={""}
            />
            <p className="text-[17px] text-[#262b33] font-[600] mt-5 ">
              {"Text to Speech"}
            </p>
            <p className="leading-4 mb-5 text-[12px] text-[#2b2b2b]">
              {"Text to Speech"}
            </p>
          </div>

          <div
            className={`${isCarsial ? "w-[300px] m-auto" : "w-[300px]"} ${
              isLang !== "eng" ? "text-right text-[22px]" : ""
            } h-full block rounded-[22px] p-3 text-[#282828] border`}
          >
            {/* Use the fetched image directly in the src attribute */}
            <img
              className="w-full  rounded-t-[6px] object-cover"
              src={img7}
              alt={""}
            />
            <p className="text-[17px] text-[#262b33] font-[600] mt-5 ">
              {"Text to Video"}
            </p>
            <p className="leading-4 mb-5 text-[12px] text-[#2b2b2b]">
              {"Text to Video"}
            </p>
          </div>

          <div
            className={`${isCarsial ? "w-[300px] m-auto" : "w-[300px]"} ${
              isLang !== "eng" ? "text-right text-[22px]" : ""
            } h-full block rounded-[22px] p-3 text-[#282828] border`}
          >
            {/* Use the fetched image directly in the src attribute */}
            <img
              className="w-full rounded-t-[6px] object-cover"
              src={img8}
              alt={""}
            />
            <p className="text-[17px] text-[#262b33] font-[600] mt-5 ">
              {"Image to Cartooni"}
            </p>
            <p className="leading-4 mb-5 text-[12px] text-[#2b2b2b]">
              {"Image to Cartooni"}
            </p>
          </div>
        </div>
      </section>

      {/* Pricing Card */}
      <section style={{ maxWidth: "1400px", margin: "auto" }}>
        <div className="mt-[60px]  text-[#262b33]">
          <p className="text-center font-[600] text-[28px] ">
            A perfect fit for evertyone
          </p>
          <div className="flex justify-center mt-[25px]  ">
            <p
              style={{
                width: "clamp(300px,100%, 800px)",
                textAlign: "center",
                color: "#2b2b2b",
              }}
            >
              Elevate your marketing without financial stress. AI Advance Tool
              offers flexible pricing plans adjustable to businesses of all
              sizes. Choose the plan that aligns with your ambitions and witness
              your promotions mount to new heights.
            </p>
          </div>
        </div>
        <div className="border-[1px] w-[320px] flex h-[60px] rounded-[12px] shadow-md p-[6px] mt-14 mb-10 m-auto ">
          <div
            style={{
              background:
                pack === false
                  ? "linear-gradient(to right, #714e91 0%, #5f619f  51%, #433761  100%)"
                  : "",
              color: pack === false ? "white" : "black",
            }}
            className={`  ${
              isLang === "eng" ? "" : "text-[22px] font-[600]"
            } w-[50%]  flex justify-center items-center rounded-md cursor-pointer`}
            onClick={() => setPack(false)}
          >
            {isLang === "eng" ? "Monthly" : "شهريا"}
          </div>
          <div
            style={{
              background: pack
                ? "linear-gradient(to right, #714e91 0%, #5f619f  51%, #433761  100%)"
                : "",
              color: pack ? "white" : "black",
            }}
            className={`  ${
              isLang === "eng" ? "" : "text-[22px] font-[600]"
            } w-[50%] flex justify-center items-center rounded-md cursor-pointer`}
            onClick={() => setPack(true)}
          >
            {isLang === "eng" ? "Yearly" : "سنوي"}
          </div>
        </div>

        <div className="flex  justify-center gap-[15px] flex-wrap border-[rgba(57,76,96,.15)] ">
          {pack === false ? (
            <>
              <PricingCard
                color={"HSLA(0,100%,66%,1)"}
                hoverColor={"#fc4444"}
                text={"white"}
                title={`Basic`}
                desc={`Basic Plan`}
                price={`$.50`}
              />
              <PricingCard
                color={"HSLA(0,100%,66%,1)"}
                hoverColor={"#fc4444"}
                text={"white"}
                title={`Basic`}
                desc={`Basic Plan`}
                price={`$.50`}
              />
              <PricingCard
                color={"HSLA(0,100%,66%,1)"}
                hoverColor={"#fc4444"}
                text={"white"}
                title={`Basic`}
                desc={`Basic Plan`}
                price={`$.50`}
              />
            </>
          ) : (
            <>
              <PricingCard
                color={"HSLA(0,100%,66%,1)"}
                hoverColor={"#fc4444"}
                text={"white"}
                title={`Basic`}
                desc={`Basic Plan`}
                price={`$.50`}
              />
              <PricingCard
                color={"HSLA(0,100%,66%,1)"}
                hoverColor={"#fc4444"}
                text={"white"}
                title={`Basic`}
                desc={`Basic Plan`}
                price={`$.50`}
              />
              <PricingCard
                color={"HSLA(0,100%,66%,1)"}
                hoverColor={"#fc4444"}
                text={"white"}
                title={`Basic`}
                desc={`Basic Plan`}
                price={`$.50`}
              />
            </>
          )}
        </div>

        <p className="text-[18px] text-center font-[500] mt-24 mb-16 text-black">
          <span className="text-[#0085FF] ">Our Subscribers</span> can enjoy
          premium AI ADVANCE TOOLS features for free
        </p>
      </section>
      {/* Featucher Cards */}

      <section>
        <Feature
          img={card1}
          bg={"#5198EB22"}
          title={"Natural Text to Speech & AI Voice Generator"}
          description={
            "Let your content go beyond text with our realistic AI voices. Generate high-quality spoken audio in any voice, style, and language. Our AI voice generator is powered by an AI model that renders human intonation and inflections with unrivaled fidelity, adjusting the delivery based on context."
          }
          des2={
            "We pride ourselves on delivering high-quality audio output that captures the nuances of human speech. Our advanced algorithms ensure that the voice is not just clear, but also carries the right emotion and intonation. This makes our Text to Speech service perfect for creators, educators, and businesses aiming to enhance their audio content.  "
          }
          des3={
            "Getting started is as easy as typing. Simply input your text, choose your preferred voice, and let our technology do the rest. With support for multiple languages and a variety of voices, our platform is designed to cater to a global audience. Join us in breaking down barriers and making digital content audible and accessible to all.  "
          }
        />
      </section>

      <section>
        <FeatureB
          img={markersection}
          bg={"#1717ff10"}
          title={"Convert Video to Cartooni"}
          description={
            "Bring your videos to life like never before with our Video to Animations service. Transform your traditional videos into vibrant animations that capture attention and imagination. Whether you're aiming to make educational content more fun or give your marketing materials a creative twist, our platform is your gateway to animation magic."
          }
          des2={
            "Our service is designed to be user-friendly, making the world of animation accessible to everyone. No need for advanced design skills—simply upload your video, and choose the animation style that fits your vision. Our technology handles the rest, turning your ideas into animated stories that resonate with audiences of all ages. "
          }
          des3={
            "Animating your videos has never been easier or more fun. Each animation is crafted to ensure your message isn't just seen but felt, with lively visuals that keep viewers engaged from start to finish. Perfect for businesses, educators, and creatives, our Video to Animations service helps you stand out in a crowded digital landscape. "
          }
        />
      </section>

      <section>
        <Feature
          img={card1}
          bg={"#5198EB22"}
          title={"Natural Text to Speech & AI Voice Generator"}
          description={
            "Step into the future of content creation with our Text to Video technology. Our platform effortlessly converts written narratives into captivating videos, merging text, images, and sound into a seamless viewing experience. Ideal for marketers, educators, and storytellers, our solution opens up a new realm of possibilities for digital content.  "
          }
          des2={
            "Our intuitive interface allows you to bring stories to life without the need for complex video editing skills. From dynamic visuals that match your message to professional-grade voiceovers, our technology ensures your content stands out. Elevate your brand or educational materials with videos that engage, inform, and entertain. "
          }
          des3={
            "Embrace the simplicity of creating videos from text. Input your script, select your preferred visuals and soundtrack, and watch as our platform transforms your words into a compelling video narrative. With support for various languages and customization options, reaching a global audience has never been easier. Join the revolution and make your content visually unforgettable. "
          }
        />
      </section>
      <section>
        <FAQ />
      </section>

      <section>
        <Footer
          img={markersection}
          bg={"#1717ff10"}
          title={"Convert Video to Cartooni Video"}
          description={
            "No more close with time-consuming design processes. AI Advance Tools4U streamlines creation, enabling you to generate perfact promotional content swiftly. Focus on strategic growth while ensuring your promotional materials set the standard."
          }
        />
      </section>
    </>
  );
};

export default Home;
