import { LOGIN, LOGOUT ,LANG} from "./actionTypes"




export const loginUser = ({ displayName, email }) => {

  return {
    type: LOGIN,
    payload: { displayName, email },
  };
};

export const logoutUser = () => {

  return {
    type: LOGOUT,
  };
};

export const language = ( lang ) => {
  return {
    type: LANG,
    payload: { lang },
  };
};