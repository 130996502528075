import React,{useState} from 'react';
import "./Styles.css"
import { useSelector } from 'react-redux';
const PricingCard = (props) => {
  const { color } = props.color;
  const [isHovered, setHovered] = useState(false);
  const isLang = useSelector(
    (state) => state.authReducer.lang
  );
  return (
    <div className={`cards   ${isLang !== "eng" ? "text-right" :""}`} style={{boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", }} >
      <div>
      <p >{props.title}</p>
      <p className={`text-${color}`}>
        {props.desc}
      </p>
      <p>{`${props.price}`}$ </p>
  
      <button  
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className='focus:outline-none'
      style={{backgroundColor: isHovered ? props.hoverColor : props.color, transition: 'background-color 0.3s ease', backgroundSize: "200% auto" , color:props.text, 
      }}>
       {isLang ==="eng"? "Get Started" :"البدء"}
      </button>
      </div>

      <ul className='text-left'>
        <li>6000+ video Templates </li>
        <li>3 Million+ Standard Media Library</li>
        <li>AI Script Generator</li>
        <li>Automated Text to Speech</li>
        <li>Collaborate with Team</li>
      </ul>
     

    </div>
  );
};

export default PricingCard;
 