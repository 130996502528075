import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

const feature = (props) => {
  return (
    <section
      className="px-4 sm:px-16 "
      style={{
        background: `${props.bg}`,
      }}
    >
      <div
        style={{ maxWidth: "1400px", margin: "auto" }}
        className="grid  
      grid-cols-10
      sm:grid-cols-10 
      md:grid-cols-10 
      lg:grid-cols-10  
      xl:grid-cols-10 
      2xl:grid-cols-10
      py-16
      gap-8
      "
      >
        <div
          className="flex flex-col  gap-8 
        col-start-1  col-end-11
        sm:col-start-1 sm:col-end-6
        md:col-start-1 ms:col-end-5
        lg:col-start-1 lg:col-end-6
        xl:col-start-1 xl:col-end-6
        2xl:col-start-1 2xl:col-end-6
        text-center sm:text-left 
        items-center sm:items-start
        text-black
        "
        >
          <p className="text-[36px] font-[700] ">{props.title}</p>
          <p className="text-[16px] font-[400] leading-[21px]">
            {props.description}
          </p>

          <p className="text-[16px] font-[400] leading-[21px]">
            {props.des2}
          </p>


          <p className="text-[16px] font-[400] leading-[21px]">
            {props.des3}
          </p>
        </div>

        <div
          className="
        col-start-1  col-end-11  
        
        
sm:col-start-6 sm:col-end-11
md:col-start-6 ms:col-end-11
lg:col-start-7 lg:col-end-11
xl:col-start-7 xl:col-end-11
2xl:col-start-7 2xl:col-end-11
"
        >
          <img
            src={`${props.img}`}
            className="h-[358px] w-[100%] object-cover rounded-2xl object-[80%]"
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default feature;
