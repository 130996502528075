import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBVjyqaEQq2dKd1eONuM9n7Sb1IrMmkfxk",
  authDomain: "ueditdesign.firebaseapp.com",
  projectId: "ueditdesign",
  storageBucket: "ueditdesign.appspot.com",
  messagingSenderId: "185638370774",
  appId: "1:185638370774:web:c313cc33f78bae126df877",
  measurementId: "G-3CFKMKX83G"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)