import React, { useState } from 'react'
import Header from '../components/Header';
import SidebarHeader from '../components/sidebarHeader';
import { useMediaQuery } from 'react-responsive'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { GiProgression } from "react-icons/gi";
import "../components/styless.css"
import PricingCard from '../components/PricingCard';
import Faq from "../components/Faq"
import Footer from '../components/Footer';
import { useSelector } from "react-redux";
import pricing from "../assets/home/pricing.jpg"

const Price = () => {
    // State
    const [menu, setMenu] = useState("")
    const [ pack, setPack] = useState(false)

    // Query
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1150px)'
    })
    const isLang = useSelector((state) => state.authReducer.lang);


    //   Carosil
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mdtablet: {
            breakpoint: { max: 992, min: 464 },
            items: 2
        },

        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    //   Function
    const handleMouseOver = (type) => {
        setMenu(type)
    }

    return (
        <>
            {
      isDesktopOrLaptop ? <Header menu={menu} setMenu={setMenu} /> : <SidebarHeader />
     }
            <div onMouseOver={() => handleMouseOver("")} className='text-black'>
                <section className='custon-section'>
                    <div className='custon-container'>
                    <p className='font-[700] text-[26px] md:text-[32px]   lg:text-[32px] text-center py-20'>Why upgrade to Promotion Maker</p>
                        <div className="custom-box-contianer">


                            <div className='custom-box-one' >
                                <p className='font-[600] text-[28px]  mb-2   '>Create with US </p>
                                <p className=' text-[20px]  '>Design made even easier </p>
                                <p className=' text-[16px] '>Craft professional content quickly.</p>
                                <ul className='pl-4 list-disc list-outside'>
                                    <li className='leading-4  text-[16px] mt-6 mb-4 '>Remove photo and video backgrounds in one click</li>
                                    <li className='leading-4  text-[16px] mb-4'>Unlimited access to  photos, templates, graphics, and videos</li>
                                    <li className='leading-4  text-[16px]'>Fix images fast with Magic Eraser.</li>
                                </ul>
                            </div>
                            <div  className=' custom-box-two' style={{overflow:"hidden", background:"red", borderRadius:"14px"}} >
                            <img src={`${pricing}`}  alt="" />

                            </div>
                        </div>
                    </div>
                </section>


<section>
    <div className='text-center'>
            <p className='text-[32px] font-[700] mb-4'>What’s included with Pro?</p>
            <p className='text-[18px] font-[400] mb-12'>Working solo? Increase your productivity, manage your brand, and build your business with Canva. Start your free trial today.</p>
            <p className='text-[14px] font-[700] mb-9'>Billing: <span style={{background:"linear-gradient(to right, #714e91 0%, #5f619f  51%, #433761  100%)", backgroundClip:"text", color:"transparent"}}> Save up to 25% </span>with Yearly</p>
    </div>
    <div className='flex justify-center '>
        <div className="border-[1px] w-[320px] flex h-[60px] rounded-[12px] shadow-md p-[6px]" >

        <div style={{
  backgroundImage: `${pack === false ? 'linear-gradient(to right, #633193 0%, #4F52A3  51%, #433761  100%)' : ''}`,
  color: pack === false ? 'white' : 'inherit' // Add any other styles as needed
}} className={`  w-[50%]  flex justify-center items-center rounded-md cursor-pointer `} onClick={()=>setPack(false)}>Monthly</div>
        
        
        
        
        <div style={{
  backgroundImage: `${pack ? 'linear-gradient(to right, #633193 0%, #4F52A3  51%, #433761  100%)' : ''}`,
  color: pack  ? 'white' : 'inherit' // Add any other styles as needed
}}  className={` w-[50%] flex justify-center items-center rounded-md cursor-pointer `} onClick={()=>setPack(true)}>Yearly</div>

    </div>
    </div>
    

<div className="flex  justify-center gap-[15px] flex-wrap border-[rgba(57,76,96,.15)] mt-[80px]">
            {pack === false ? (
              <>
                <PricingCard
                  color={"white"}
                  hoverColor={"#f4f2f5"}
                  text={"#525659"}
                  title={isLang === "eng" ? "Free" : "حر"}
                  desc={isLang === "eng" ? "Free Package" : "حزمة مجانية"}
                  price={"0"}
                />
                <PricingCard
                  color={"HSLA(0,100%,66%,1)"}
                  hoverColor={"#fc4444"}
                  text={"white"}
                  title={isLang === "eng" ? "Business" : "عمل"}
                  desc={isLang === "eng" ? "Business Plan" : "خطة عمل"}
                  price={"10"}
                />
                <PricingCard
                  color={"#7731D8"}
                  hoverColor={"#612dae"}
                  text={"white"}
                  title={isLang === "eng" ? "Premium" : "غالي"}
                  desc={isLang === "eng" ? "Premium Plan" : "الخطة المميزة"}
                  price={"12"}
                />
                <PricingCard
                  color={"#525659"}
                  hoverColor={"rgba(13,18,22,.86)"}
                  text={"#fff"}
                  title={isLang === "eng" ? "One Time" : "مره واحده"}
                  desc={isLang === "eng" ? "One Time Plan" : "خطة لمرة واحدة"}
                  price={"10"}
                />
              </>
            ) : (
              <>
                <PricingCard
                  color={"white"}
                  hoverColor={"#f4f2f5"}
                  text={"#525659"}
                  title={isLang === "eng" ? "Free" : "حر"}
                  desc={isLang === "eng" ? "Free Package" : "حزمة مجانية"}
                  price={"0"}
                />
                <PricingCard
                  color={"HSLA(0,100%,66%,1)"}
                  hoverColor={"#fc4444"}
                  text={"white"}
                  title={isLang === "eng" ? "Business" : "عمل"}
                  desc={isLang === "eng" ? "Business Plan" : "خطة عمل"}
                  price={"110"}
                />
                <PricingCard
                  color={"#7731D8"}
                  hoverColor={"#612dae"}
                  text={"white"}
                  title={isLang === "eng" ? "Premium" : "غالي"}
                  desc={isLang === "eng" ? "Premium Plan" : "الخطة المميزة"}
                  price={"120"}
                />
                <PricingCard
                  color={"#525659"}
                  hoverColor={"rgba(13,18,22,.86)"}
                  text={"#fff"}
                  title={isLang === "eng" ? "One Time" : "مره واحده"}
                  desc={isLang === "eng" ? "One Time Plan" : "خطة لمرة واحدة"}
                  price={"100"}
                />
              </>
            )}
          </div>




        {/* <PricingCard />
        <PricingCard /> */}
    





    </section>
<section className='my-20'>
<Faq />
</section>
<footer>
<Footer />
</footer>
            </div>

        </>
    )
}

export default Price