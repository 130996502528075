// authReducer.js
import { LOGIN, LOGOUT ,LANG} from './actionTypes';

const initialState = {
  isAuthenticated: false,
  name: null,
  email: null,
  lang: "eng",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        name: action.payload.displayName,
        email: action.payload.email,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        name: null,
        email: null,
      };
      case LANG:
      return {
        ...state,
        lang:action.payload.lang
      };


      
    default:
      return state;
  }
};

export default authReducer;
