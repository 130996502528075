import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { Directions } from '@mui/icons-material';
export default function AccordionExpandDefault() {
  const isLang = useSelector((state) => state.authReducer.lang);

  return (
    <div style={{maxWidth:"1400px" , margin:"auto", paddingBottom:"50px" }}>

          <p className='text-[#2b2b2b] text-[42px] font-[700] text-center mb-8 '>{isLang==="eng"? "Frequently asked questions":"أسئلة مكررة"}</p>
        
        
        
        
        <div 
  
                style={{direction:"ltr" ,  width:"clamp(320px, 100%, 850px)", margin:"auto"}}
                >
          <Accordion defaultExpanded style={{ boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <p className='font-[600] text-[16px] text-[#262b33]'>What is Advance Ai Tools and what do you offer?</p>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className='text-[#2b2b2b]'>
        Advance Ai Tools is a platform that provides a diverse range of templates for various purposes. Whether you need templates for websites, presentations, documents, or other projects, we've got you covered
        </Typography>
      </AccordionDetails>
    </Accordion>



          <Accordion style={{ boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <p className='font-[600] text-[16px] text-[#262b33]'>How do I browse and find templates on Advance Ai Tools?</p>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='text-[#2b2b2b]'>
              Navigating our website is easy. Simply browse through the categories or use our search feature to find the templates that suit your needs. Once you find a template you like, you can preview it before downloading.
            
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <p className='font-[600] text-[16px] text-[#262b33]'>Are the templates on Advance Ai Tools free to use?</p>
            </AccordionSummary>
            <AccordionDetails className='text-[#2b2b2b]'>
              <Typography>
              We offer a mix of free and premium templates. Free templates can be used without any charge, while premium templates may require a one-time purchase. Check the pricing details on each template's page for more information.
             
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <p className='font-[600] text-[16px] text-[#262b33]'>Can I customize the templates to fit my specific requirements?</p>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='text-[#2b2b2b]'>
              Absolutely! Our templates are designed to be easily customizable. You can edit text, images, colors, and other elements to tailor the template to your unique needs using compatible software.
           
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <p className='font-[600] text-[16px] text-[#262b33]'>How do I download a template from Advance Ai Tools?</p>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='text-[#2b2b2b]'>
              To download a template, simply click on the "Download" button on the template's page. If it's a free template, you may be able to download it directly. For premium templates, follow the checkout process to access the download link.
             
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>


    

    </div>
  );
}
// 