import React from "react";
import { NavLink } from "react-router-dom";
import markersection from "../assets/home/img5.jpg"

const FeatureTwo = (props) => {
  return (
    <section style={{ background: `${props.bg}` }} className={`px-4 sm:px-10`}>
      <div
        style={{ maxWidth: "1400px", margin: "auto" }}
       className="grid  
          grid-cols-10
          sm:grid-cols-12 
          md:grid-cols-12
          lg:grid-cols-12  
          xl:grid-cols-12
            
          py-16
          gap-8 "
      >
        <div
          className="
          col-start-1  col-end-11 order-2
          sm:order-1
          sm:col-start-1 sm:col-end-6
          md:col-start-1 ms:col-end-5
          lg:col-start-1 lg:col-end-4
          xl:col-start-1 xl:col-end-6"
        >
          <div className="w-full  h-[550px]   overflow-hidden relative">
            <img
              src={markersection}
              className=" h-full w-full  absolute top-0"
              style={{ borderRadius: "12px", background: "red" }}
            ></img>
          </div>
        </div>

        <div
          className="flex flex-col  gap-6


    col-start-1  col-end-11  order-1
    
    sm:col-start-7 sm:col-end-12 sm:order-2
    md:col-start-7 ms:col-end-12
    lg:col-start-7 lg:col-end-12
    xl:col-start-7 xl:col-end-12

    text-center sm:text-left 
    items-center sm:items-start
    text-black
    "
        >
          <p className="text-[36px] font-[700]">{props.title}</p>
          <p className="text-[17px] font-[400] leading-[21px]">
            {props.description}
          </p>

          <p className="text-[17px] font-[400] leading-[21px]">
            {props.des2}
          </p>

          <p className="text-[17px] font-[400] leading-[21px]">
            {props.des3}
          </p>
        </div>
      </div>
    </section>
  );
};

export default FeatureTwo;
